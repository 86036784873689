import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

const useStyles = makeStyles(() => ({

    center: {
        margin: "20px auto 0 auto",
        width: "100% !important",
    },
    button1: {
        borderRadius: '16px',
        background: '#084AD8',
        color: '#fff',
        width: 256,
        height: 56,
        cursor: 'pointer',
        transition: 'background ease-in-out .3s',
        '&:hover': {
            background: '#215CDC',
            transition: 'background ease-in-out .3s'
        }
    },
    button2: {
        borderRadius: '0',
        background: 'transparent',
        color: '#fff',
        width: 280,
        height: 56,
        cursor: 'pointer',
        '& p': {
            position: 'relative',
            paddingBottom: '8px',
            borderBottom: '2px solid #ffffff20',
        },
        '& p:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -2,
            width: 0,
            height: '2px',
            background: '#fff',
            transition: 'all ease-in-out .6s',
        },
        '& p:hover::after': {
            width: '100%',
            transition: 'all ease-in-out .6s',
        }
    }
}))

export default function Hero({handleLink, screenType}) {
    const classes = useStyles()

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 'h1') return 40
                if (s === 'l2') return 18
                break
            case 'phone':
                if (s === 'h1') return 40
                if (s === 'l2') return 18
                break
            default:
                if (s === 'h1') return 64
                if (s === 'l2') return 20
                break
        }
    }

    return (
        <section id='hero' style={{backgroundColor: '#10283F'}}>

            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', pt: (screenType === 'desktop') ? '80px' : '40px'}}>

                <Grid container sx={{px: (screenType === 'desktop') ? 0 : 4}} alignItems='center' spacing={2}>

                    <Grid item container size={{sm: (screenType === 'desktop') ? 7 : 6, xs:12}} spacing={0} sx={{height: '400px'}}>
                        <Grid size={{xs: 12}}>
                            <Typography variant='h1' sx={{color: '#fff', fontWeight: 500, fontSize: calcSize('h1')}}>Tartozáskezelés személyre szabottan</Typography>
                            {(screenType !== 'desktop') ?
                                <Typography sx={{color: '#ffffff80', fontWeight: 500, fontSize: calcSize('l2'), pt: '32px'}}>Szakértői és személyre szabott megoldások a követeléskezelésben.</Typography>
                            :
                                <Box>
                                    <Typography sx={{color: '#ffffff80', fontWeight: 500, fontSize: calcSize('l2'), pt: '32px'}}>Szakértői és személyre szabott megoldások</Typography>
                                    <Typography sx={{color: '#ffffff80', fontWeight: 500, fontSize: calcSize('l2') }}>a követeléskezelésben.</Typography>
                                </Box>
                            }
                        </Grid>
                        <Grid size={{md: 5, xs: 12}}>
                            <Box className={[classes.button1, (screenType !== 'desktop') ? classes.center : '']} alignItems='center' display='flex' justifyContent='center'
                                 onClick={() => handleLink('contact')}>
                                <Typography sx={{letterSpacing: 1, }}>Lépjen kapcsolatba velünk</Typography>
                            </Box>
                        </Grid>
                        <Grid size={{md: 7, xs: 12}}>
                            <Box className={[classes.button2, (screenType !== 'desktop') ? classes.center : '']} alignItems='center' display='flex' justifyContent='center'
                                 onClick={() => handleLink('services')}>
                                <Typography sx={{letterSpacing: 1, }}>Ismerje meg a szolgáltatásainkat</Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid size={{sm: (screenType === 'desktop') ? 5 : 6, xs: 12}}>
                        <img src='/images/hero-image.webp' alt='hero-image' width={'100%'} height={'auto'} />
                    </Grid>
                </Grid>

            </Box>

            {/*<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{maxWidth: 1200, m: '0 auto', pt: '40px'}}>
                <Stack>
                    <Typography variant='h1' sx={{color: '#fff', fontWeight: 500, fontSize: 64}}>Tartozáskezelés személyre szabottan</Typography>
                    <Typography sx={{color: '#ffffff80', fontWeight: 500, fontSize: 20, pt: '32px'}}>Szakértői és személyre szabott megoldások.</Typography>
                    <Typography sx={{color: '#ffffff80', fontWeight: 500, fontSize: 20, pb: '32px'}}>a követeléskezelésben.</Typography>

                    <Stack direction='row' spacing={4} alignItems='center' sx={{pt: '12px'}}>
                        <Box className={classes.button1} alignItems='center' display='flex' justifyContent='center'
                             onClick={() => handleLink('contact')}>
                            <Typography sx={{letterSpacing: 1, }}>Lépjen kapcsolatba velünk</Typography>
                        </Box>
                        <Box className={classes.button2} alignItems='center' display='flex' justifyContent='center'
                             onClick={() => handleLink('services')}>
                            <Typography sx={{letterSpacing: 1, }}>Ismerje meg a szolgáltatásainkat</Typography>
                        </Box>
                    </Stack>
                </Stack>

                <img src='/images/hero-image.webp' alt='hero-image' width={503} height={586} />
            </Stack>*/}
        </section>
    )
}

Hero.propTypes = {
    handleLink: PropTypes.func,
    screenType: PropTypes.string
}