import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useState} from "react";
import Grid from '@mui/material/Grid2';
import PropTypes from "prop-types";

const data = [
    {
        question: 'Mikor érdemes követeléskezelőhöz fordulni?',
        answer: 'Érdemes követeléskezelőhöz fordulni, ha egy partner vagy ügyfél huzamosabb ideje nem fizeti ki a tartozását, ismételten csak ígérget, vagy elérhetetlenné válik. Minél hamarabb lép, annál nagyobb az esély a sikeres behajtásra, elkerülve a hosszas és költséges jogi eljárásokat.',
    }, {
        question: 'Mennyi idő alatt rendeződik egy követelés?',
        answer: '60 napon belül garantáltan érünk el érdemi eredményt, ami gyorsabb és költséghatékonyabb, mint egy hosszadalmas jogi eljárás.',
    }, {
        question: 'Mennyibe kerül a szolgáltatáscsomag?',
        answer: 'Díjazásunk egyedileg meghatározott sikerdíj alapján történik.',
    }, {
        question: 'Magánszemélyek is igénybe vehetik a szolgáltatáscsomagot?',
        answer: 'Igen, szolgáltatáscsomagunk nemcsak vállalkozások, hanem magánszemélyek számára is elérhető.',
    }, {
        question: 'Mi történik, ha a behajtás sikertelen?',
        answer: 'A jogi és ügyvédi költségeket saját költségünkre megelőlegezzük és támogatjuk a teljes folyamatot, amíg megbízónk megkapja követelését.',
    }
]

export default function Gyik({screenType}) {

    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 't1') return 40

                break
            case 'phone':
                if (s === 't1') return 40

                break
            default:
                if (s === 't1') return 48

                break
        }
    }

    return (
        <section id='gyik' style={{background: '#E6EDFB'}}>
            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', py: (screenType === 'dektop') ? '80px' : '40px'}}>
                <Grid container spacing={5} sx={{p: 4}}>
                    <Grid size={{lg: 5, md: 12}}>
                        <Typography fontSize={calcSize('t1')} fontWeight={500} color='#10283F'>Gyakran ismételt kérdések</Typography>
                    </Grid>
                    <Grid size={{lg: 7, md: 12}}>
                        {data.map((item, idx) => {
                            return (
                                <Accordion key={idx}
                                           sx={{width: '100%', boxShadow: '0'}}
                                           disableGutters
                                           expanded={expanded === `panel${idx}`}
                                           onChange={handleChange(`panel${idx}`)}>
                                    <AccordionSummary expandIcon={<ExpandMore />} sx={{height: '90px', border: '1px solid #0D203208'}}>
                                        <Typography fontSize={18} fontWeight={400} color='#10283F'>{item.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{pb: 4}}><Typography fontSize={16} fontWeight={400} color='#10283F'>{item.answer}</Typography></AccordionDetails>
                                </Accordion>)
                        })}
                    </Grid>
                </Grid>
            </Box>
            {/*<Stack direction='column' justifyContent='space-between' sx={{maxWidth: 1200, m: '0 auto', py: '80px'}}>

                <Stack direction='row' spacing={5}>
                    <Stack sx={{width: '40%'}}>

                    </Stack>
                    <Stack sx={{width: '60%'}}>

                        {data.map((item, idx) => {
                            return (
                                <Accordion key={idx}
                                           sx={{width: '100%', boxShadow: '0'}}
                                           disableGutters
                                           expanded={expanded === `panel${idx}`}
                                           onChange={handleChange(`panel${idx}`)}>
                                    <AccordionSummary expandIcon={<ExpandMore />} sx={{height: '90px', border: '1px solid #0D203208'}}>
                                        <Typography fontSize={18} fontWeight={400} color='#10283F'>{item.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{pb: 4}}><Typography fontSize={16} fontWeight={400} color='#10283F'>{item.answer}</Typography></AccordionDetails>
                                </Accordion>)
                        })}

                    </Stack>
                </Stack>
            </Stack>*/}
        </section>
    )
}

Gyik.propTypes = {
    screenType: PropTypes.string
}