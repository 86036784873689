import {Box, Stack, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

const useStyles = makeStyles(() => ({

    button: {
        borderRadius: '16px',
        background: '#084AD8',
        color: '#fff',
        width: 256,
        height: 56,
        cursor: 'pointer',
        transition: 'background ease-in-out .3s',
        marginTop: 50,
        '&:hover': {
            background: '#215CDC',
            transition: 'background ease-in-out .3s'
        }
    }
}))

export default function Services({handleLink, screenType}) {
    const classes = useStyles()

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 't1') return 40
                if (s === 't2') return 28
                if (s === 't3') return 20
                break
            case 'phone':
                if (s === 't1') return 40
                if (s === 't2') return 24
                if (s === 't3') return 18
                break
            default:
                if (s === 't1') return 48
                if (s === 't2') return 32
                if (s === 't3') return 20
                break
        }
    }

    return (
        <section id='services'>
            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', py: (screenType === 'desktop')  ? '80px' : '40px', px: (screenType === 'desktop') ? 0 : 4}}>

                <Typography fontSize={calcSize('t1')} fontWeight={500} color='#10283F' sx={{mb: '40px'}}>Szolgáltatásaink</Typography>

                <Grid container spacing={5}>

                    <Grid size={{lg: 6, md: 12}}>

                        <Box sx={{background: '#10283F', borderRadius: '24px', p: '40px', width: '100%'}}>
                            <Typography fontSize={calcSize('t2')} fontWeight={500} color='#fff'>Követeléskezelési szolgáltatás csomag</Typography>

                            <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{pt: '50px'}}>csomag tartalma</Typography>

                            <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '50px'}}>
                                <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                                <Stack spacing={1}>
                                    <Typography color={'#fff'} fontWeight={600} fontSize={calcSize('t3')}>Éves rendelkezésre állás</Typography>
                                    <Typography color={'#fff'} fontWeight={400} fontSize={16}>Egész évben biztosítjuk támogatásunkat.</Typography>
                                </Stack>
                            </Stack>

                            <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                                <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                                <Stack spacing={1}>
                                    <Typography color={'#fff'} fontWeight={600} fontSize={calcSize('t3')}>Előzetes környezettanulmány</Typography>
                                    <Typography color={'#fff'} fontWeight={400} fontSize={16}>Minden ügy pontos feltérképezése.</Typography>
                                </Stack>
                            </Stack>

                            <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                                <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                                <Stack spacing={1}>
                                    <Typography color={'#fff'} fontWeight={600} fontSize={calcSize('t3')}>Kapcsolatfelvétel több csatornán</Typography>
                                    <Typography color={'#fff'} fontWeight={400} fontSize={16}>Írásban, telefonon és személyesen, szakértők által.</Typography>
                                </Stack>
                            </Stack>

                            <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                                <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                                <Stack spacing={1}>
                                    <Typography color={'#fff'} fontWeight={600} fontSize={calcSize('t3')}>Szakértői tanácsadás, ügyvédi asszisztencia</Typography>
                                    <Typography color={'#fff'} fontWeight={400} fontSize={16}>Segítünk eligazodnia a követeléskezelés jogi hátterében.</Typography>
                                </Stack>
                            </Stack>

                            <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                                <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                                <Stack spacing={1}>
                                    <Typography color={'#fff'} fontWeight={600} fontSize={20}>Prevenciós tevékenységek</Typography>
                                    <Typography color={'#fff'} fontWeight={400} fontSize={16}>Szerződéses védelem, online pecsét és partnerleinformálás a biztonságos működésért.</Typography>
                                </Stack>
                            </Stack>

                            <Box className={classes.button} alignItems='center' display='flex' justifyContent='center'
                                 onClick={() => handleLink('contact')}>
                                <Typography>Érdekel a csomagajánlat</Typography>
                            </Box>

                        </Box>
                    </Grid>

                    <Grid size={{lg: 6, md: 12}}>
                        <Box sx={{background: '#E6EDFB', borderRadius: '24px', p: '40px'}}>
                            <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{mb: '30px'}}>előnyők</Typography>

                            <Stack spacing={3}>
                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Munkaerő megtakarítás</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Kollégáit nem kell a követelések kezelésére átcsoportosítania.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Személyre szabott megoldások</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Minden követeléskezelési ügyet egyedi stratégiával kezelünk.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Költséghatékony és gyors megoldás</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Megbízóink hamarabb hozzájuthatnak pénzükhöz, elkerülve a drága és bizonytalan kimenetelű jogi eljárásokat.</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box sx={{background: 'transparent', border: '2px solid #E7E9EB', borderRadius: '24px', p: '40px', mt: '40px'}}>
                            <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{mb: '30px'}}>sikerdíj és megtérülés</Typography>

                            <Stack spacing={3}>
                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Sikerdíj alapú működés</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>3.000.000 Ft feletti követeléseknél együtt alkudjuk ki a sikerdíj mértékét.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Késedelmi kamat</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Vállalkozásoknak jegybanki alapkamat + 8%, magánszemélyeknek jegybanki alapkamat érvényesíthető.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3} sx={{background: '#E6EDFB', borderRadius: '24px', p: 2}}>
                                    <img src={'/images/services-list-icon-3.png'} alt={'services-list-icon-3'} width={24} height={32} />
                                    <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Plusz előny: A behajtott késedelmi kamat fedezheti a sikerdíjunkat, így Ön a teljes követelését visszakaphatja.</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            {/*<Stack direction='column' justifyContent='space-between' sx={{maxWidth: 1200, m: '0 auto', py: '60px'}}>

                <Typography fontSize={48} fontWeight={500} color='#10283F' sx={{mb: '40px'}}>Szolgáltatásaink</Typography>

                <Stack direction='row' spacing={6}>

                    <Box sx={{background: '#10283F', borderRadius: '24px', p: '40px', width: '50%'}}>
                        <Typography fontSize={32} fontWeight={500} color='#fff'>Követeléskezelési szolgáltatás csomag</Typography>

                        <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{pt: '50px'}}>csomag tartalma</Typography>

                        <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '50px'}}>
                            <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                            <Stack spacing={1}>
                                <Typography color={'#fff'} fontWeight={600} fontSize={20}>Éves rendelkezésre állás</Typography>
                                <Typography color={'#fff'} fontWeight={400} fontSize={16}>Egész évben biztosítjuk támogatásunkat.</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                            <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                            <Stack spacing={1}>
                                <Typography color={'#fff'} fontWeight={600} fontSize={20}>Előzetes környezettanulmány</Typography>
                                <Typography color={'#fff'} fontWeight={400} fontSize={16}>Minden ügy pontos feltérképezése.</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                            <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                            <Stack spacing={1}>
                                <Typography color={'#fff'} fontWeight={600} fontSize={20}>Kapcsolatfelvétel több csatornán</Typography>
                                <Typography color={'#fff'} fontWeight={400} fontSize={16}>Írásban, telefonon és személyesen, szakértők által.</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                            <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                            <Stack spacing={1}>
                                <Typography color={'#fff'} fontWeight={600} fontSize={20}>Szakértői tanácsadás, ügyvédi asszisztencia</Typography>
                                <Typography color={'#fff'} fontWeight={400} fontSize={16}>Segítünk eligazodnia a követeléskezelés jogi hátterében.</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction='row' alignItems='center' spacing={3} sx={{pt: '40px'}}>
                            <img src={'/images/services-list-icon.png'} alt={'services-list-icon'} width={24} height={38} />
                            <Stack spacing={1}>
                                <Typography color={'#fff'} fontWeight={600} fontSize={20}>Prevenciós tevékenységek</Typography>
                                <Typography color={'#fff'} fontWeight={400} fontSize={16}>Szerződéses védelem, online pecsét és partnerleinformálás a biztonságos működésért.</Typography>
                            </Stack>
                        </Stack>

                        <Box className={classes.button} alignItems='center' display='flex' justifyContent='center'
                             onClick={() => handleLink('contact')}>
                            <Typography>Érdekel a csomagajánlat</Typography>
                        </Box>

                    </Box>

                    <Stack sx={{width: '50%'}} spacing={6}>

                        <Box sx={{background: '#E6EDFB', borderRadius: '24px', p: '40px'}}>
                            <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{mb: '30px'}}>előnyők</Typography>

                            <Stack spacing={3}>
                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Munkaerő megtakarítás</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Kollégáit nem kell a követelések kezelésére átcsoportosítania.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Személyre szabott megoldások</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Minden követeléskezelési ügyet egyedi stratégiával kezelünk.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Költséghatékony és gyors megoldás</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Megbízóink hamarabb hozzájuthatnak pénzükhöz, elkerülve a drága és bizonytalan kimenetelű jogi eljárásokat.</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box sx={{background: 'transparent', border: '2px solid #E7E9EB', borderRadius: '24px', p: '40px'}}>
                            <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{mb: '30px'}}>sikerdíj és megtérülés</Typography>

                            <Stack spacing={3}>
                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Sikerdíj alapú működés</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>3.000.000 Ft feletti követeléseknél együtt alkudjuk ki a sikerdíj mértékét.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3}>
                                    <img src={'/images/services-list-icon-2.png'} alt={'services-list-icon-2'} width={12} height={26} />
                                    <Stack spacing={1}>
                                        <Typography color={'#10283F'} fontWeight={600} fontSize={18}>Késedelmi kamat</Typography>
                                        <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Vállalkozásoknak jegybanki alapkamat + 8%, magánszemélyeknek jegybanki alapkamat érvényesíthető.</Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction='row' spacing={3} sx={{background: '#E6EDFB', borderRadius: '24px', p: 2}}>
                                    <img src={'/images/services-list-icon-3.png'} alt={'services-list-icon-3'} width={24} height={32} />
                                    <Typography color={'#10283F'} fontWeight={400} fontSize={16}>Plusz előny: A behajtott késedelmi kamat fedezheti a sikerdíjunkat, így Ön a teljes követelését visszakaphatja.</Typography>
                                </Stack>
                            </Stack>
                        </Box>

                    </Stack>

                </Stack>

            </Stack>*/}
        </section>
    )
}

Services.propTypes = {
    handleLink: PropTypes.func,
    screenType: PropTypes.string
}