import {Box, Typography} from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";

export default function Partners({screenType}) {

    return (
        <section id='partners' style={{borderBottom: '1px solid #E7E9EB'}}>

            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', py: (screenType === 'desktop')  ? '80px' : '40px'}}>
                <Typography textTransform='uppercase' sx={{fontWeight: 500, fontSize: 14, letterSpacing: '1px', color: '#6E7984', textAlign: 'center'}}>ügyfeleink, Akik megbíztak bennünk</Typography>

                <Grid container sx={{px: (screenType === 'desktop') ? 0 : 4, pt: (screenType === 'desktop') ? '60px' : '20px', pb: (screenType === 'desktop') ? '20px' : 0}} alignItems='center' spacing={2} justifyContent='center'>

                    <Grid item size={{lg: 2, sm: 4, xs:6}} sx={{flexGrow: 1, textAlign: 'center'}}>
                        <img src={'/images/partner-1.png'} alt='partner-1' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                    </Grid>

                    <Grid item size={{lg: 2, sm: 4, xs:6}} sx={{flexGrow: 1, textAlign: 'center'}}>
                        <img src={'/images/partner-2.png'} alt='partner-2' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                    </Grid>

                    <Grid item size={{lg: 2, sm: 4, xs:6}} sx={{flexGrow: 1, textAlign: 'center'}}>
                        <img src={'/images/partner-3.png'} alt='partner-3' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                    </Grid>

                    <Grid item size={{lg: 2, sm: 4, xs:6}} sx={{flexGrow: 1, textAlign: 'center'}}>
                        <img src={'/images/partner-4.png'} alt='partner-4' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                    </Grid>

                    <Grid item size={{lg: 2, sm: 4, xs:6}} sx={{flexGrow: 1, textAlign: 'center'}}>
                        <img src={'/images/partner-5.png'} alt='partner-5' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                    </Grid>

                    <Grid item size={{lg: 2, sm: 4, xs:6}} sx={{flexGrow: 1, textAlign: 'center'}}>
                        <img src={'/images/partner-6.png'} alt='partner-6' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px', margin: '0 auto'}} />
                    </Grid>

                </Grid>

            </Box>

            {/*<Stack direction='column' justifyContent='space-between' alignItems='center' sx={{maxWidth: 1200, m: '0 auto', py: '60px'}}>
                <Typography textTransform='uppercase' sx={{fontWeight: 500, fontSize: 14, letterSpacing: '1px', color: '#6E7984'}}>ügyfeleink, Akik megbíztak bennünk</Typography>

                    <Grid2 container spacing={9} sx={{pt: '60px', pb: '20px'}} alignItems='center' justifyContent='center'>
                        <Grid2 item lg={4} sm={6}>
                            <img src={'/images/partner-1.png'} alt='partner-1' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                        </Grid2>
                        <Grid2 item lg={4} sm={6}>
                            <img src={'/images/partner-2.png'} alt='partner-2' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                        </Grid2>

                        <Grid2 item lg={4} sm={6}>
                            <img src={'/images/partner-3.png'} alt='partner-3' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                        </Grid2>
                        <Grid2 item lg={4} sm={6}>
                            <img src={'/images/partner-4.png'} alt='partner-4' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                        </Grid2>

                        <Grid2 item lg={4} sm={6}>
                            <img src={'/images/partner-5.png'} alt='partner-5' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                        </Grid2>
                        <Grid2 item lg={4} sm={6}>
                            <img src={'/images/partner-6.png'} alt='partner-6' style={{maxWidth: '200px', maxHeight: '80px', padding: '15px'}} />
                        </Grid2>

                    </Grid2>

            </Stack>*/}
        </section>
    )
}

Partners.propTypes = {
    screenType: PropTypes.string
}