import React, {useEffect, useState} from "react";
import {Alert, Box, createTheme,
    //Slide,
    ThemeProvider} from "@mui/material";
import {AlertProvider} from "./contexts/alert-provider";
import Header from "./sections/header";
import Footer from "./sections/footer";
import HomePage from "./pages/home";
import CompanyDetailsPage from "./pages/companydetails";
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import PrivacyPolicyPage from "./pages/privacypolicy";
//import MobileMenu from "./sections/mobilemenu";


const THEME = createTheme({
    typography: {
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    app: {
      position: 'relative'
    }
})

export default function Layout() {

    const navigate = useNavigate();
    const location = useLocation()

    const [scrolledHeader, setScrolledHeader] = useState(false)
    const [scrolledY, setScrolledY] = useState(0)
    const [scrollDirection, setScrollDirection] = useState('')

    const [screenType, setScreenType] = useState('desktop')

    const [alertOpen, setAlertOpen] = useState(false)
    //const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const handleScroll = () => {
        const posY = window.scrollY

        if (scrolledY < posY) {
            setScrollDirection('down')
        } else {
            setScrollDirection('up')
        }

        setScrolledY(posY)
        return (posY >= 88) ? setScrolledHeader(true) : setScrolledHeader(false)
    }

    useEffect(() => {
        handleResize()
    });

    const handleResize = () => {
        const windowWidth = window.innerWidth

        if (windowWidth >= 1200) {
            setScreenType('desktop')
        } else if (windowWidth < 1200 && windowWidth >= 640) {
            setScreenType('tablet')
        } else {
            setScreenType('phone')
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true})
        window.addEventListener('resize', handleResize, {passive: true})

        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleResize)
        }
    })

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const handleLink = async (s, f, m) => {

        if (location.pathname !== '/') {
            navigate('/')
            await sleep(100)
        }

        const sectionY = document.getElementById(s)?.offsetTop

        if (sectionY !== undefined) {
            let currentY = sectionY

            if (m) {
                if (window.scrollY < sectionY) {
                    currentY = currentY + 88
                }
            } else if (window.scrollY >= sectionY) {
                if ((scrollDirection === 'up' && scrolledHeader) || f) {
                    currentY = currentY - 88
                }
            }

            await window.scroll({top: currentY, behavior: 'smooth'});
        } else {
            await window.scroll({top: 0, behavior: 'smooth'});
        }
    }

    const setOpenAlert = () => {
        setAlertOpen(true)

        setTimeout(() => {
            setAlertOpen(false)
        }, 5000)
    }

    /*const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen)
    }*/

    return(
        <ThemeProvider theme={THEME}>
            <AlertProvider>
                <Box className='app'>
                    <Header scrolledHeader={scrolledHeader} handleLink={handleLink} scrollDirection={scrollDirection}
                            screenType={screenType} //toggleMobileMenu={toggleMobileMenu}
                    />

                    <Routes>
                        <Route index path='/' element={<HomePage screenType={screenType} setOpenAlert={setOpenAlert} handleLink={handleLink} />} />
                        <Route path='details' element={<CompanyDetailsPage screenType={screenType} />} />
                        <Route path='policies' element={<PrivacyPolicyPage screenType={screenType} />} />
                    </Routes>

                    <Footer handleLink={handleLink} screenType={screenType} />

                    {alertOpen &&
                        <Box sx={{position: 'sticky', bottom: 0, left: 0}}>
                            <Alert severity='error'>
                                {'Hiba történt az üzenet elküldése során. Kérjük, próbálja meg újra később vagy lépjen kapcsolatba velünk más módon.'}
                            </Alert>
                        </Box>
                    }

                    {/*
                    {mobileMenuOpen &&
                        <Slide direction='left' in={mobileMenuOpen} mountOnEnter unmountOnExit
                               {...(mobileMenuOpen ? { timeout: 1000 } : {})}>
                            <Box sx={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                                background: '#fff', zIndex: 99 }}>
                                <MobileMenu toggleMobileMenu={toggleMobileMenu} />
                            </Box>
                        </Slide>
                    }*/}

                </Box>
            </AlertProvider>
        </ThemeProvider>
    )
}