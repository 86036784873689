import PropTypes from "prop-types";
import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";


export default function PrivacyPolicyPage({screenType}) {

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 't1') return 40

                break
            case 'phone':
                if (s === 't1') return 40

                break
            default:
                if (s === 't1') return 48

                break
        }
    }

    return (
        <section id='details' style={{background: '#fff'}}>
            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', py: (screenType === 'dektop') ? '80px' : '40px'}}>
                <Grid container spacing={5} sx={{p: 4}}>
                    <Grid size={{lg: 5, md: 12}}>
                        <Typography fontSize={calcSize('t1')} fontWeight={500} color='#10283F'>Adatvédelmi tájékoztató</Typography>
                    </Grid>
                    <Grid size={{lg: 7, md: 12}}>
                        <Typography fontSize={18} fontWeight={500} color='#10283F' sx={{pb: 4, mt: 4}}>1. Az adatkezelés célja</Typography>
                        <Typography fontSize={16} fontWeight={400} color='#10283F'>A kapcsolatfelvételi űrlapon megadott személyes adatokat kizárólag az Ön megkeresésének feldolgozására, válaszadásra és a szolgáltatásainkkal kapcsolatos tájékoztatásra használjuk fel. Az adatkezelés jogalapja az Ön hozzájárulása (GDPR 6. cikk (1) bek. a) pont), amelyet az űrlap kitöltésével és elküldésével ad meg.</Typography>

                        <Typography fontSize={18} fontWeight={500} color='#10283F' sx={{pb: 4, mt: 4}}>2. Kezelt adatok köre és megőrzési idő</Typography>
                        <Typography fontSize={16} fontWeight={400} color='#10283F'>
                            A kapcsolatfelvétel során az alábbi személyes adatokat rögzítjük:
                            <ul style={{padding: '5px 20px'}}>
                                <li>Név</li>
                                <li>E-mail cím</li>
                                <li>Telefonszám</li>
                                <li>Üzenet tartalma</li>
                            </ul>
                            Az adatokat kizárólag a megkeresés feldolgozásához szükséges ideig tároljuk, de legfeljebb 6 hónapig, kivéve, ha jogszabály hosszabb megőrzési időt ír elő.
                        </Typography>

                        <Typography fontSize={18} fontWeight={500} color='#10283F' sx={{pb: 4, mt: 4}}>3. Adattovábbítás és adatbiztonság</Typography>
                        <Typography fontSize={16} fontWeight={400} color='#10283F'>A kapcsolatfelvételi űrlapon megadott személyes adatokat kizárólag az Ön megkeresésének feldolgozására, válaszadásra és a szolgáltatásainkkal kapcsolatos tájékoztatásra használjuk fel. Az adatkezelés jogalapja az Ön hozzájárulása (GDPR 6. cikk (1) bek. a) pont), amelyet az űrlap kitöltésével és elküldésével ad meg.</Typography>

                        <Typography fontSize={18} fontWeight={500} color='#10283F' sx={{pb: 4, mt: 4}}>4. Az Ön jogai és jogorvoslati lehetőségei</Typography>
                        <Typography fontSize={16} fontWeight={400} color='#10283F'>
                            Ön jogosult:
                            <ul style={{padding: '5px 20px'}}>
                                <li>Tájékoztatást kérni az adatkezelésről</li>
                                <li>Hozzáférést kérni az Önről tárolt adatokhoz</li>
                                <li>Kérni az adatok helyesbítését, törlését vagy kezelésének korlátozását</li>
                                <li>Hozzájárulását bármikor visszavonni</li>
                            </ul>
                            Jogai gyakorlása érdekében kérjük, lépjen kapcsolatba velünk az info@tartozaskezeles.hu elérhetőségen. Ha úgy érzi, hogy jogai sérültek, panaszt nyújthat be a Nemzeti Adatvédelmi és Információszabadság Hatóságnál (NAIH).
                        </Typography>

                    </Grid>
                </Grid>
            </Box>
        </section>
    )
}

PrivacyPolicyPage.propTypes = {
    screenType: PropTypes.string
}