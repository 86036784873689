import {useCallback, useRef, useState} from "react";


const useRecaptcha = () => {

    const [captchaToken, setCaptchaToken] = useState('')
    const recaptchaRef = useRef(null)

    const handleRecaptcha = useCallback((token) => {
        setCaptchaToken(token || '')
    }, [])

    return {captchaToken, setCaptchaToken, recaptchaRef, handleRecaptcha}
}

export default useRecaptcha