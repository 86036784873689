import {Box, Slide, Stack, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import {useState} from "react";
import MobileMenu from "./mobilemenu";
import classNames from 'classnames';

const useStyles = makeStyles(() => ({

    section: {
        background: '#10283F',
        borderBottom: '1px solid #10283F',
        position: 'relative',
        color: '#fff'
    },
    sectionAlt: {
        color: '#10283F',
        borderBottom: '1px solid #E7E9EB',
        background: '#fff',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 1
    },
    menuItem: {
        position: 'relative',
        cursor: 'pointer',
        '&::after': {
            content: '""',
            position: 'absolute',
            borderRadius: '2px',
            left: 'calc(50% - 20px)',
            bottom: -8,
            width: '40px',
            height: '2px',
            transform: 'scaleX(0)',
            transition: 'transform ease-in-out .3s',
            //backgroundColor: '#ffffff20',
        },'&:hover::after': {
            transform: 'scaleX(1)',
            transition: 'transform ease-in-out .3s'
        }
    },
    miNormal: {
        '&::after': {
            backgroundColor: '#ffffff20'
        }
    },
    miAlt: {
        '&::after': {
            backgroundColor: '#10283F20'
        }
    },
    contactButton: {
        borderRadius: '16px',
        padding: '8px 24px',
        transition: 'background ease-in-out .3s',
        cursor: 'pointer',
        '&:hover': {
            transition: 'background ease-in-out .3s'
        }
    },
    cbNormal: {
        border: '1.5px solid #ffffff20',
        '&:hover': {
            background: '#ffffff20',
        }
    },
    cbAlt: {
        border: '1.5px solid #CFD4D9',
        '&:hover': {
            background: '#CFD4D920',
        }
    },

    mobileMenu: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
    },

    navToggle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '30px',
        width: '30px',
    },

    navToggleMobile: {
        justifyContent: 'space-between',
        height: '20px',
        width: '20px',
    },

    navToggleBar: {
        height: '2px',
        borderRadius: '4px',
        width: '100%',
        margin: '4px 0',
        backgroundColor: '#fff',
        transition: 'all 250ms ease-in-out'
    },

    navToggleBarDark: {
        backgroundColor: '#10283F',
    },

    x: {
        backgroundColor: '#10283F',
        margin: '0',
        '&:nth-of-type(1)': {
            backgroundColor: '#10283F',
            transition: 'all 250ms ease-in-out',
            transform: 'rotate(45deg)',
            transformOrigin: 'top left',
            width: '26px'
        },
        '&:nth-of-type(2)': {
            backgroundColor: '#10283F',
            transition: 'all 250ms ease-in-out',
            transform: 'rotate(-45deg)',
            transformOrigin: 'bottom left',
            width: '26px'
        },
    }

}))

export default function Header({scrolledHeader, handleLink, scrollDirection, screenType, toggleMobileMenu}) {
    const classes = useStyles()

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const handleMobileMenu = (src) => {
        setMobileMenuOpen(false)
        handleLink(src, false, true)
    }

    return (
        (screenType === 'desktop') ? (
            <header id='header' className={(scrollDirection === 'up' && scrolledHeader) ? classes.sectionAlt : classes.section}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{height: '88px', width: '100%', maxWidth: 1200, m: '0 auto'}}>
                    <a href='/'>
                        <img src={(scrollDirection === 'up' && scrolledHeader) ? '/logo/header-logo-dark.png' : '/logo/header-logo.png'} alt='logo' width={250} height={30} />
                    </a>

                    <Stack direction='row' spacing={3} alignItems='center'>
                        <Box className={[classes.menuItem, (scrollDirection === 'up' && scrolledHeader) ? classes.miAlt : classes.miNormal]}
                             onClick={async () => await handleLink('about')}>
                            <Typography fontSize={14} fontWeight={500}>{'Rólunk'}</Typography>
                        </Box>
                        <Box className={[classes.menuItem, (scrollDirection === 'up' && scrolledHeader) ? classes.miAlt : classes.miNormal]}
                             onClick={async () => await handleLink('services')}>
                            <Typography fontSize={14} fontWeight={500}>{'Szolgáltatásaink'}</Typography>
                        </Box>
                        <Box className={[classes.menuItem, (scrollDirection === 'up' && scrolledHeader) ? classes.miAlt : classes.miNormal]}
                             onClick={async () => await handleLink('stepbystep')}>
                            <Typography fontSize={14} fontWeight={500}>{'Tartozáskezelés lépései'}</Typography>
                        </Box>
                        <Box className={[classes.menuItem, (scrollDirection === 'up' && scrolledHeader) ? classes.miAlt : classes.miNormal]}
                             onClick={async () => await handleLink('gyik')}>
                            <Typography fontSize={14} fontWeight={500}>{'GYIK'}</Typography>
                        </Box>
                        <Box className={[classes.menuItem, (scrollDirection === 'up' && scrolledHeader) ? classes.miAlt : classes.miNormal]}>
                            <Typography fontSize={14} fontWeight={500}>{'Ügyfeleink mondták'}</Typography>
                        </Box>
                        <Box className={[classes.contactButton, (scrollDirection === 'up' && scrolledHeader) ? classes.cbAlt : classes.cbNormal]} onClick={async () => await handleLink('contact')}>
                            <Typography fontSize={14} fontWeight={500} sx={{py: '8px'}}>{'Kapcsolatfelvétel'}</Typography>
                        </Box>

                    </Stack>
                </Stack>
            </header>
        ) : (
            <>
                <header id='header' className={((scrollDirection === 'up' && scrolledHeader) || mobileMenuOpen) ? classes.sectionAlt : classes.section}>
                    <Box className={mobileMenuOpen && classes.mobileMenu}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'
                               sx={{height: '80px', width: '100%', m: '0 auto', px: 4, background: mobileMenuOpen ? '#fff' : 'transparent'}}>
                            <a href='/'>
                                <img src={((scrollDirection === 'up' && scrolledHeader) || mobileMenuOpen) ? '/logo/header-logo-dark.png' : '/logo/header-logo.png'} alt='logo' width={250} height={30} />
                            </a>

                            <Box onClick={() =>
                                //toggleMobileMenu()
                                setMobileMenuOpen(!mobileMenuOpen)}
                                 className={classNames(classes.navToggle, mobileMenuOpen && classes.navToggleMobile)}
                            >
                                <Box className={classNames(classes.navToggleBar, mobileMenuOpen && classes.x, (scrollDirection === 'up' && scrolledHeader) && classes.navToggleBarDark)}></Box>
                                <Box className={classNames(classes.navToggleBar, mobileMenuOpen && classes.x, (scrollDirection === 'up' && scrolledHeader) && classes.navToggleBarDark)}></Box>
                                {/*<img src={(scrollDirection === 'up' && scrolledHeader) ? '/images/mobile-header-hamburger-icon-dark.png' : '/images/mobile-header-hamburger-icon.png'} alt='mobile-header-hamburger-icon' width={28} height={28} />*/}
                            </Box>
                        </Stack>
                        {mobileMenuOpen &&
                            <Slide direction='left' in={mobileMenuOpen} mountOnEnter unmountOnExit
                                   timeout={250}>
                                <Box>
                                    <MobileMenu handleMobileMenu={handleMobileMenu} />
                                </Box>
                            </Slide>
                        }
                    </Box>
                </header>
            </>
        )
    )
}

Header.propTypes = {
    scrolledHeader: PropTypes.bool,
    handleLink: PropTypes.func,
    scrollDirection: PropTypes.string,
    screenType: PropTypes.string,
    toggleMobileMenu: PropTypes.func
}