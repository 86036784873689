import {Box, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import Grid from '@mui/material/Grid2';
import {useEffect} from "react";


const useStyles = makeStyles(() => ({

    button: {
        borderRadius: '16px',
        background: '#084AD8',
        color: '#fff',
        width: 256,
        height: 56,
        cursor: 'pointer',
        transition: 'background ease-in-out .3s',
        '&:hover': {
            background: '#215CDC',
            transition: 'background ease-in-out .3s'
        }
    },

    circleAfter: {
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            height: 'calc(100% - 32px)',
            width: '1px',
            backgroundColor: '#425477',
            left: '50%',
        }
    },

    circle: {
        position: 'relative',
    },

    circleText: {
        width: 72,
        height: 72,
        border: '1px solid #425477',
        borderRadius: '36px',
        textAlign: 'center',
        color: '#fff'
    }


}))

const steps = [
    {id: 1, title: 'Kapcsolatfelvétel', text: 'Ügyfélkapcsolati menedzserünk felméri az Ön igényeit és bemutatja az együttműködési lehetőségeket.'},
    {id: 2, title: 'Adós elemzése', text: 'Az adós pénzügyi helyzetének vizsgálata és a szükséges dokumentumok begyűjtése.'},
    {id: 3, title: 'Stratégia kidolgozása', text: 'Egyedi követeléskezelési terv készítése az Ön céljai és az adós helyzete alapján.'},
    {id: 4, title: 'Intézkedések megkezdése', text: 'Hivatalos megkeresések, követelési és szükség esetén jogi lépések megtétele.'},
    {id: 5, title: 'Folyamatos nyomon követés', text: 'Rendszeres riportokkal biztosítjuk a teljes átláthatóságot az ügy állapotáról, folyamatban levő intézkedésekről.'},
    {id: 6, title: 'Ügy lezárása', text: 'A követelés rendezése, az eredmények kiértékelése és a végső dokumentáció átadása.'},
]

export default function StepByStep({handleLink, screenType}) {
    const classes = useStyles();

    useEffect(() => {
        const posY = window.scrollY
        console.log(posY)
    });

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 't1') return 40

                break
            case 'phone':
                if (s === 't1') return 40

                break
            default:
                if (s === 't1') return 48

                break
        }
    }


    return (
        <section id='stepbystep' style={{background: '#10283F'}}>
            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', py: (screenType === 'desktop')  ? '80px' : '40px'}}>
                <Grid container spacing={10} sx={{p: 4}}>
                    <Grid item size={{lg: 6, md: 12}}>
                        <Box sx={{position: 'sticky', top: 100}}>
                            <Typography fontSize={calcSize('t1')} fontWeight={500} color='#fff'>Tartozáskezelés lépésről lépésre</Typography>

                            <Typography fontSize={18} fontWeight={400} color='#fff' sx={{my: 6}}>Ismerd meg, hogyan zajlik a követelés és tartozásrendezés folyamata lépésről lépésre.</Typography>

                            <Box className={classes.button} alignItems='center' display='flex' justifyContent='center'
                                 onClick={() => handleLink('contact')}>
                                <Typography>Kapcsolatfelvétel</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item size={{lg: 6, md: 12}}>
                        <Stack spacing={0}>
                            {steps.map((step, idx) => {
                                return (
                                    <Stack direction='row' spacing={5} key={idx} sx={{py: 2.5}}>
                                        <Box className={(idx >= steps.length-1) ? classes.circle : classes.circleAfter}>
                                            <Typography className={classes.circleText}
                                                        fontSize={24}
                                                        fontWeight={500}
                                                        lineHeight={'72px'}>{step.id}</Typography>
                                        </Box>
                                        <Stack spacing={1.5}>
                                            <Typography fontSize={20} fontWeight={600} color='#fff'>{step.title}</Typography>
                                            <Typography fontSize={16} fontWeight={400} color='#fff'>{step.text}</Typography>
                                        </Stack>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            {/*<Stack direction='column' justifyContent='space-between' sx={{maxWidth: 1200, m: '0 auto', py: '80px'}}>
                <Stack direction='row' spacing={10}>
                    <Stack sx={{width: '50%'}}>
                        <Box sx={{position: 'sticky', top: 100}}>
                            <Typography fontSize={48} fontWeight={500} color='#fff'>Tartozáskezelés lépésről lépésre</Typography>

                            <Typography fontSize={18} fontWeight={400} color='#fff' sx={{my: 6}}>Ismerd meg, hogyan zajlik a követelés és tartozásrendezés folyamata lépésről lépésre.</Typography>

                            <Box className={classes.button} alignItems='center' display='flex' justifyContent='center'
                                 onClick={() => handleLink('contact')}>
                                <Typography>Kapcsolatfelvétel</Typography>
                            </Box>
                        </Box>
                    </Stack>


                    <Stack sx={{width: '50%'}} spacing={8}>

                        {steps.map((step, idx) => {
                            return (
                                <Stack direction='row' spacing={5} key={idx}>
                                    <Box>
                                        <Typography fontSize={24} fontWeight={500} color='#fff'
                                                    sx={{width: 72, height: 72, border: '1px solid #fff', borderRadius: '36px', textAlign: 'center', lineHeight: '72px'}}>{step.id}</Typography>
                                    </Box>
                                    <Stack spacing={1.5}>
                                        <Typography fontSize={20} fontWeight={600} color='#fff'>{step.title}</Typography>
                                        <Typography fontSize={16} fontWeight={400} color='#fff'>{step.text}</Typography>
                                    </Stack>
                                </Stack>
                            )
                        })}

                    </Stack>

                </Stack>
            </Stack>*/}
        </section>
    )
}

StepByStep.propTypes = {
    handleLink: PropTypes.func,
    screenType: PropTypes.string
}