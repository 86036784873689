import {createContext, useState} from "react";


const ALERT_TIME = 5000
const initialState = {title: '', text: '', type: ''}

const AlertContext = createContext({
    ...initialState,
    setAlert: () => {}
})

export const AlertProvider = ({children}) => {
    const [text, setText] = useState('')
    const [type, setType] = useState('')
    const [open, setOpen] = useState(false)

    const setAlert = (text, type, open) => {

        setText(text)
        setType(type)
        setOpen(true)

        setTimeout(() => {
            setText('')
            setType('')
            setOpen(false)
        }, ALERT_TIME)
    }

    return (
        <AlertContext.Provider value={{text, type, open, setAlert}}>
            {children}
        </AlertContext.Provider>
    )
}

export default AlertContext