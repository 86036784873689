//import {makeStyles} from "@mui/styles";
import {Box, Divider, Link, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";

/*const useStyles = makeStyles(() => ({

}))*/

export default function MobileMenu({handleMobileMenu}) {
    //const classes = useStyles()

    return (
        <Box sx={{background: '#fff'}}>

            <Divider color={'#E7E9EB'} />

            <Stack direction='column' justifyContent={'space-between'} sx={{pb: 6, height: 'calc(100vh - 80px)'}}>

                <Stack direction='column' alignItems='center' spacing={3} sx={{m: 5}}>

                    <Box width='100%' onClick={async () => await handleMobileMenu('about')}>
                        <Typography color='#10283F' fontSize={20} fontWeight={500} textAlign='left'>{'Rólunk'}</Typography>
                    </Box>
                    <Box width='100%' onClick={async () => await handleMobileMenu('services')}>
                        <Typography color='#10283F' fontSize={20} fontWeight={500}>{'Szolgáltatásaink'}</Typography>
                    </Box>
                    <Box width='100%' onClick={async () => await handleMobileMenu('stepbystep')}>
                        <Typography color='#10283F' fontSize={20} fontWeight={500}>{'Tartozáskezelés lépései'}</Typography>
                    </Box>
                    <Box width='100%' onClick={async () => await handleMobileMenu('gyik')}>
                        <Typography color='#10283F' fontSize={20} fontWeight={500}>{'GYIK'}</Typography>
                    </Box>
                    <Box width='100%'>
                        <Typography color='#10283F' fontSize={20} fontWeight={500}>{'Ügyfeleink mondták'}</Typography>
                    </Box>

                    <Box sx={{height: 10}} />

                    <Box width='100%' sx={{background: '#084AD8', py:2, borderRadius: 5}}
                         onClick={async () => await handleMobileMenu('contact')}>
                        <Typography fontSize={20} fontWeight={500} color='#fff' textAlign='center'>{'Kapcsolatfelvétel'}</Typography>
                    </Box>
                </Stack>

                <Stack direction='column' spacing={5} sx={{mx: 4}}>

                    <Stack direction='row' spacing={1} sx={{mb: 4}}>
                        <img src='/images/mobile-menu-phone-icon.png' alt='footer-phone-icon' width={48} height={48} />
                        <Stack spacing={.5}>
                            <Typography fontSize={12} fontWeight={600} color='#8B949D'
                                        textTransform='uppercase' letterSpacing={1.4}>hívjon minket</Typography>
                            <Link href='tel:+36706461848' style={{ textDecoration: 'none' }}>
                                <Typography fontSize={16} fontWeight={400} color='#10283F'
                                            sx={{borderBottom: '1px solid #E7E9EB', pb: .3}}>+36 70 646 1848</Typography>
                            </Link>
                        </Stack>
                    </Stack>

                    <Stack direction='row' spacing={1}>
                        <img src='/images/mobile-menu-address-icon.png' alt='footer-address-icon' width={48} height={48} />
                        <Stack spacing={.5}>
                            <Typography fontSize={12} fontWeight={600} color='#8B949D'
                                        textTransform='uppercase' letterSpacing={1.4}>elérhetőségünk</Typography>
                            <Typography fontSize={16} fontWeight={400} color='#10283F'
                                        sx={{borderBottom: '1px solid #E7E9EB', pb: .3}}>6000 Kecskemét, Kisfaludy utca 8.</Typography>
                        </Stack>
                    </Stack>

                </Stack>

            </Stack>

        </Box>
    )
}

MobileMenu.propTypes = {
    handleMobileMenu: PropTypes.func,
}