import Hero from "../sections/hero";
import Partners from "../sections/partners";
import About from "../sections/about";
import Services from "../sections/services";
import StepByStep from "../sections/stepbystep";
import Gyik from "../sections/gyik";
import Contact from "../sections/contact";
import PropTypes from "prop-types";

const HomePage = ({handleLink, screenType, setOpenAlert}) => {
    return (
        <>
            <Hero handleLink={handleLink} screenType={screenType} />
            <Partners screenType={screenType} />
            <About screenType={screenType} />
            <Services handleLink={handleLink} screenType={screenType} />
            <StepByStep handleLink={handleLink} screenType={screenType} />
            <Gyik screenType={screenType} />
            <Contact setOpenAlert={setOpenAlert} screenType={screenType} />
        </>
    )
}

HomePage.propTypes = {
    handleLink: PropTypes.func,
    screenType: PropTypes.string,
    setOpenAlert: PropTypes.func,
}

export default HomePage