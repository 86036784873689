import {Box, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';

export default function About({screenType}) {

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 't1') return 40
                if (s === 't2') return '50px'
                if (s === 't3') return 40

                break
            case 'phone':
                if (s === 't1') return 40
                if (s === 't2') return '25px'
                if (s === 't3') return 32

                break
            default:
                if (s === 't1') return 48
                if (s === 't2') return '50px'
                if (s === 't3') return 40

                break
        }
    }

    return (
        <section id='about' style={{borderBottom: '1px solid #E7E9EB'}}>

            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', py: (screenType === 'desktop')  ? '80px' : '40px', px: (screenType === 'desktop') ? 0 : 4}}>

                <Grid container spacing={5}>

                    <Grid size={{lg: 4, sm: 4, xs: 12}}>
                        <Typography fontSize={calcSize('t1')} fontWeight={500} color='#10283F' sx={{pr: '200px'}}>Rólunk</Typography>
                    </Grid>

                    <Grid size={{lg: 8, sm: 8, xs: 12}} sx={{mt: (screenType === 'desktop') ? '20px' : 0}}>

                        <Typography fontSize={18} color='#10283F' lineHeight={1.6}>2013 óta a hazai követeléskezelési piac meghatározó szereplőjeként professzionális és hatékony megoldásokat kínálunk ügyfeleink számára. Tapasztalt csapatunk kiemelt figyelmet fordít partnereink igényeire, és személyre szabott stratégiákkal segítjük követeléseik rendezését.</Typography>
                        <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{pt: calcSize('t2')}}>cégünk számokban</Typography>

                        <Grid container item sx={{py: calcSize('t2')}} spacing={2}>
                            <Grid size={{lg: 3, sm: 6, xs: 6}}>
                                <Box sx={{flexGrow: 1}}>
                                    <Typography color={'#2567F5'} fontWeight={500} fontSize={calcSize('t3')}>500+</Typography>
                                    <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Sikeresen lezárt ügy</Typography>
                                </Box>
                            </Grid>
                            <Grid size={{lg: 3, sm: 6, xs: 6}}>
                                <Box sx={{flexGrow: 1}}>
                                    <Typography color={'#2567F5'} fontWeight={500} fontSize={calcSize('t3')}>20+</Typography>
                                    <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Szakmai tapasztalat</Typography>
                                </Box>
                            </Grid>
                            <Grid size={{lg: 3, sm: 6, xs: 6}}>
                                <Box sx={{flexGrow: 1}}>
                                    <Typography color={'#2567F5'} fontWeight={500} fontSize={calcSize('t3')}>91%</Typography>
                                    <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Sikerességi arány</Typography>
                                </Box>
                            </Grid>
                            <Grid size={{lg: 3, sm: 6, xs: 6}}>
                                <Box sx={{flexGrow: 1}}>
                                    <Typography color={'#2567F5'} fontWeight={500} fontSize={calcSize('t3')}>50+</Typography>
                                    <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Elégedett ügyfél</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <img src={'/images/about-image.webp'} alt='about-image' width='100%' />

                <Grid container spacing={5}>

                    <Grid size={{lg: 12, sm: 4, xs: 12}}>
                        <Typography textAlign='left' textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{py: calcSize('t2')}}>cégünk értékei</Typography>
                    </Grid>

                    <Grid item container size={{lg: 12, sm: 8, xs: 12}} sx={{pt: (screenType === 'tablet') ? '40px' : 0}}>

                        <Grid size={{lg: 4, md: 12}}>

                            <Stack sx={{flexGrow: 1}}>
                                <img src={'/images/about-icon-1.png'} alt='about-icon-1' width='48px' height='48px' />
                                <Typography color={'#10283F'} fontWeight={600} fontSize={20} sx={{py: 2}}>Szakértelem</Typography>
                                <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Tapasztalt csapatunk professzionális megoldásokat kínál minden követeléskezelési helyzetre.</Typography>
                            </Stack>

                        </Grid>

                        <Grid size={{lg: 4, md: 12}}>

                            <Stack sx={{flexGrow: 1}}>
                                <img src={'/images/about-icon-2.png'} alt='about-icon-2' width='48px' height='48px' />
                                <Typography color={'#10283F'} fontWeight={600} fontSize={20} sx={{py: 2}}>Átláthatóság</Typography>
                                <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Folyamataink során ügyfeleinket minden lépésről tájékoztatjuk, biztosítva a teljeskörű bizalmat.</Typography>
                            </Stack>

                        </Grid>

                        <Grid size={{lg: 4, md: 12}}>

                            <Stack sx={{flexGrow: 1}}>
                                <img src={'/images/about-icon-3.png'} alt='about-icon-3' width='48px' height='48px' />
                                <Typography color={'#10283F'} fontWeight={600} fontSize={20} sx={{py: 2}}>Hatékonyság</Typography>
                                <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Eredményorientált, stratégiai megoldásokkal segítjük a cégek pénzügyi stabilitásának helyreállítását.</Typography>
                            </Stack>

                        </Grid>
                    </Grid>

                </Grid>

            </Box>

            {/*<Stack direction='column' justifyContent='space-between' sx={{maxWidth: 1200, m: '0 auto', py: '80px'}}>
                <Stack direction='row'>

                    <Typography fontSize={48} fontWeight={500} color='#10283F' sx={{pr: '200px'}}>Rólunk</Typography>

                    <Stack sx={{mt: '20px'}}>
                        <Typography fontSize={18} color='#10283F' lineHeight={1.6}>2013 óta a hazai követeléskezelési piac meghatározó szereplőjeként professzionális és hatékony megoldásokat kínálunk ügyfeleink számára. Tapasztalt csapatunk kiemelt figyelmet fordít partnereink igényeire, és személyre szabott stratégiákkal segítjük követeléseik rendezését.</Typography>
                        <Typography textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{pt: '50px'}}>cégünk számokban</Typography>
                        <Stack direction='row' sx={{py: '50px'}}>
                            <Box sx={{flexGrow: 1}}>
                                <Typography color={'#2567F5'} fontWeight={500} fontSize={40}>500+</Typography>
                                <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Sikeresen lezárt ügy</Typography>
                            </Box>
                            <Box sx={{flexGrow: 1}}>
                                <Typography color={'#2567F5'} fontWeight={500} fontSize={40}>20+</Typography>
                                <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Szakmai tapasztalat</Typography>
                            </Box>
                            <Box sx={{flexGrow: 1}}>
                                <Typography color={'#2567F5'} fontWeight={500} fontSize={40}>91%</Typography>
                                <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Sikerességi arány</Typography>
                            </Box>
                            <Box sx={{flexGrow: 1}}>
                                <Typography color={'#2567F5'} fontWeight={500} fontSize={40}>50+</Typography>
                                <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Elégedett ügyfél</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>

                <img src={'/images/about-image.webp'} alt='about-image' width='100%' />

                <Typography textAlign='left' textTransform='uppercase' fontSize={14} fontWeight={500} color={'#6E7984'} letterSpacing={1} sx={{pt: '50px'}}>cégünk értékei</Typography>

                <Stack direction='row' sx={{pt: '40px'}} spacing={5}>
                    <Stack sx={{flexGrow: 1}}>
                        <img src={'/images/about-icon-1.png'} alt='about-icon-1' width='48px' height='48px' />
                        <Typography color={'#10283F'} fontWeight={600} fontSize={20} sx={{py: 2}}>Szakértelem</Typography>
                        <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Tapasztalt csapatunk professzionális megoldásokat kínál minden követeléskezelési helyzetre.</Typography>
                    </Stack>

                    <Stack sx={{flexGrow: 1}}>
                        <img src={'/images/about-icon-2.png'} alt='about-icon-2' width='48px' height='48px' />
                        <Typography color={'#10283F'} fontWeight={600} fontSize={20} sx={{py: 2}}>Átláthatóság</Typography>
                        <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Folyamataink során ügyfeleinket minden lépésről tájékoztatjuk, biztosítva a teljeskörű bizalmat.</Typography>
                    </Stack>

                    <Stack sx={{flexGrow: 1}}>
                        <img src={'/images/about-icon-3.png'} alt='about-icon-3' width='48px' height='48px' />
                        <Typography color={'#10283F'} fontWeight={600} fontSize={20} sx={{py: 2}}>Hatékonyság</Typography>
                        <Typography color={'#10283F'} fontWeight={400} fontSize={18}>Eredményorientált, stratégiai megoldásokkal segítjük a cégek pénzügyi stabilitásának helyreállítását.</Typography>
                    </Stack>
                </Stack>


            </Stack>*/}
        </section>
    )
}

About.propTypes = {
    screenType: PropTypes.string
}