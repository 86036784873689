import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";


export default function CompanyDetailsPage({screenType}) {

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 't1') return 40

                break
            case 'phone':
                if (s === 't1') return 40

                break
            default:
                if (s === 't1') return 48

                break
        }
    }

    return (
        <section id='details' style={{background: '#fff'}}>
            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', py: (screenType === 'dektop') ? '80px' : '40px'}}>
                <Grid container spacing={5} sx={{p: 4}}>
                    <Grid size={{lg: 5, md: 12}}>
                        <Typography fontSize={calcSize('t1')} fontWeight={500} color='#10283F'>Cégadatok</Typography>
                    </Grid>
                    <Grid size={{lg: 7, md: 12}}>
                        <Typography fontSize={18} fontWeight={500} color='#10283F' sx={{pb: 4, mt: 4}}>Tartozáskezelés.hu Korlátolt Felelősségű Társaság</Typography>
                        <Grid item container spacing={2}>
                            <Grid size={{md: 4, sm: 12}}>
                                <Typography fontSize={14} fontWeight={500} color='#6E7984' textTransform='uppercase' letterSpacing={.5}>széhely címe</Typography>
                            </Grid>
                            <Grid size={{md: 8, sm: 12}}>
                                <Typography fontSize={16} fontWeight={400} color='#6E7984'>6000 Kecskemét, Kisfaludy utca 8.</Typography>
                            </Grid>

                            <Grid size={{md: 4, sm: 12}}>
                                <Typography fontSize={14} fontWeight={500} color='#6E7984' textTransform='uppercase' letterSpacing={.5}>cégjegyzékszám</Typography>
                            </Grid>
                            <Grid size={{md: 8, sm: 12}}>
                                <Typography fontSize={16} fontWeight={400} color='#6E7984'>03 09 138353</Typography>
                            </Grid>

                            <Grid size={{md: 4, sm: 12}}>
                                <Typography fontSize={14} fontWeight={500} color='#6E7984' textTransform='uppercase' letterSpacing={.5}>adószám</Typography>
                            </Grid>
                            <Grid size={{md: 8, sm: 12}}>
                                <Typography fontSize={16} fontWeight={400} color='#6E7984'>14658709-2-03</Typography>
                            </Grid>

                            <Grid size={{md: 4, sm: 12}}>
                                <Typography fontSize={14} fontWeight={500} color='#6E7984' textTransform='uppercase' letterSpacing={.5}>bankszámlaszám</Typography>
                            </Grid>
                            <Grid size={{md: 8, sm: 12}}>
                                <Typography fontSize={16} fontWeight={400} color='#6E7984'>10700103-75041346-51100005</Typography>
                                <Typography fontSize={16} fontWeight={400} color='#6E7984'>CIB Bank Zrt. Kecskeméti fiók</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </section>
    )
}

CompanyDetailsPage.propTypes = {
    screenType: PropTypes.string
}