
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from "./layout";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <React.StrictMode>
            <Layout />
        </React.StrictMode>
    </BrowserRouter>
)