import {Box, Divider, Link, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";

export default function Footer({handleLink, screenType}) {

    function FooterContactComponent() {
        return (
            <Grid size={{lg: 3, sm: 6, xs: 12}}>
                <ul style={{listStyle: 'none', flexGrow: 1}}>
                    <li onClick={() => handleLink('about', true)} style={{cursor: 'pointer'}}>
                        <Typography fontSize={14} fontWeight={400} color='#fff'>Rólunk</Typography></li>
                    <li onClick={() => handleLink('services', true)} style={{cursor: 'pointer'}}>
                        <Typography fontSize={14} fontWeight={400} color='#fff' sx={{py: 1}}>Szolgáltatásaink</Typography></li>
                    <li onClick={() => handleLink('stepbystep', true)} style={{cursor: 'pointer'}}>
                        <Typography fontSize={14} fontWeight={400} color='#fff'>Tartozáskezelés lépései</Typography></li>
                    <li onClick={() => handleLink('gyik', true)} style={{cursor: 'pointer'}}>
                        <Typography fontSize={14} fontWeight={400} color='#fff' sx={{py: 1}}>GYIK</Typography></li>
                    <li><Typography fontSize={14} fontWeight={400} color='#fff'>Ügyfeleink mondták</Typography></li>
                </ul>
            </Grid>
        )
    }

    return (
        <footer id='footer' style={{background: '#10283F'}}>
            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', pt: (screenType === 'desktop') ? '80px' : '40px'}}>
                <Grid container spacing={5} sx={{p: 4}}>

                    <Grid size={{lg: 5, md: 12}}>
                        <Stack spacing={2}>
                            <img src='/logo/header-logo.png' alt='logo' width={250} height={30} />
                            <Typography fontSize={16} fontWeight={400} color='#fff'>Szakértői és személyre szabott megoldások a követeléskezelésben.</Typography>
                        </Stack>
                    </Grid>

                    {(screenType !== 'phone') &&
                        <FooterContactComponent />
                    }

                    <Grid size={{lg: 4, sm: 6, xs: 12}}>
                        <Stack direction='row' spacing={1} sx={{mb: 4}}>
                            <img src='/images/footer-phone-icon.png' alt='footer-phone-icon' width={48} height={48} />
                            <Stack spacing={.5}>
                                <Typography fontSize={12} fontWeight={600} color='#8B949D'
                                            textTransform='uppercase' letterSpacing={1.4}>hívjon minket</Typography>
                                <Link href='tel:+36706461848' style={{ textDecoration: 'none' }}>
                                    <Typography fontSize={16} fontWeight={400} color='#fff'
                                                sx={{borderBottom: '1px solid #ffffff20', pb: .3}}>+36 70 646 1848</Typography>
                                </Link>
                            </Stack>
                        </Stack>

                        <Stack direction='row' spacing={1}>
                            <img src='/images/footer-address-icon.png' alt='footer-address-icon' width={48} height={48} />
                            <Stack spacing={.5}>
                                <Typography fontSize={12} fontWeight={600} color='#8B949D'
                                            textTransform='uppercase' letterSpacing={1.4}>elérhetőségünk</Typography>
                                <Typography fontSize={16} fontWeight={400} color='#fff'
                                            sx={{borderBottom: '1px solid #ffffff20', pb: .3}}>6000 Kecskemét, Kisfaludy utca 8.</Typography>
                            </Stack>
                        </Stack>
                    </Grid>

                    {(screenType === 'phone') &&
                        <FooterContactComponent />
                    }

                    <Grid item container size={{xl: 12}} spacing={1}>
                        {(screenType === 'desktop') ?
                            <Stack direction='row' spacing={1}>
                                <Typography fontSize={14} fontWeight={400} color='#8B949D' sx={{pr: 2}}>© 2025 Tartozáskezelés.hu</Typography>
                                <a href='/policies' style={{textDecoration: 'none'}}>
                                    <Typography fontSize={14} fontWeight={400} color='#8B949D'>Adatvédelmi tájékoztató</Typography>
                                </a>
                                <Divider orientation="vertical" flexItem variant='middle' sx={{background: '#8B949D'}} />
                                <a href='/details' style={{textDecoration: 'none'}}>
                                    <Typography fontSize={14} fontWeight={400} color='#8B949D'>Cégadatok</Typography>
                                </a>
                                <Divider orientation="vertical" flexItem variant='middle' sx={{background: '#8B949D'}} />
                                <Typography fontSize={14} fontWeight={400} color='#8B949D'>Visszaélések bejelentése</Typography>
                            </Stack>
                            :
                            <Stack direction='column' spacing={1}>
                                <a href='/policies' style={{textDecoration: 'none'}}>
                                    <Typography fontSize={14} fontWeight={400} color='#8B949D'>Adatvédelmi tájékoztató</Typography>
                                </a>

                                <a href='/details' style={{textDecoration: 'none'}}>
                                    <Typography fontSize={14} fontWeight={400} color='#8B949D'>Cégadatok</Typography>
                                </a>

                                <Typography fontSize={14} fontWeight={400} color='#8B949D'>Visszaélések bejelentése</Typography>

                                <Typography fontSize={14} fontWeight={400} color='#8B949D' sx={{pt: 2}}>© 2025 Tartozáskezelés.hu</Typography>
                            </Stack>
                        }

                    </Grid>
                </Grid>
            </Box>

            {/*<Stack direction='column' justifyContent='space-between' sx={{maxWidth: 1200, m: '0 auto', pt: '80px'}}>
                <Stack direction='row' spacing={5} sx={{flexGrow: 1}}>
                    <Stack spacing={2}>
                        <img src='/logo/header-logo.png' alt='logo' width={250} height={30} />
                        <Typography fontSize={16} fontWeight={400} color='#fff'>Szakértői és személyre szabott megoldások a követeléskezelésben.</Typography>
                    </Stack>

                    <ul style={{listStyle: 'none', flexGrow: 1}}>
                        <li onClick={() => handleLink('about', true)} style={{cursor: 'pointer'}}>
                            <Typography fontSize={14} fontWeight={400} color='#fff'>Rólunk</Typography></li>
                        <li onClick={() => handleLink('services', true)} style={{cursor: 'pointer'}}>
                            <Typography fontSize={14} fontWeight={400} color='#fff' sx={{py: 1}}>Szolgáltatásaink</Typography></li>
                        <li onClick={() => handleLink('stepbystep', true)} style={{cursor: 'pointer'}}>
                            <Typography fontSize={14} fontWeight={400} color='#fff'>Tartozáskezelés lépései</Typography></li>
                        <li onClick={() => handleLink('gyik', true)} style={{cursor: 'pointer'}}>
                            <Typography fontSize={14} fontWeight={400} color='#fff' sx={{py: 1}}>GYIK</Typography></li>
                        <li><Typography fontSize={14} fontWeight={400} color='#fff'>Ügyfeleink mondták</Typography></li>
                    </ul>

                    <Stack spacing={2} sx={{flexGrow: 1}}>
                        <Stack direction='row' spacing={1}>
                            <img src='/images/footer-phone-icon.png' alt='footer-phone-icon' width={48} height={48} />
                            <Stack spacing={.5}>
                                <Typography fontSize={12} fontWeight={600} color='#8B949D'
                                            textTransform='uppercase' letterSpacing={1.4}>hívjon minket</Typography>
                                <Typography fontSize={16} fontWeight={400} color='#fff'
                                            sx={{borderBottom: '1px solid #ffffff20', pb: .3}}>+36 70 646 1848</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction='row' spacing={1}>
                            <img src='/images/footer-address-icon.png' alt='footer-address-icon' width={48} height={48} />
                            <Stack spacing={.5}>
                                <Typography fontSize={12} fontWeight={600} color='#8B949D'
                                            textTransform='uppercase' letterSpacing={1.4}>elérhetőségünk</Typography>
                                <Typography fontSize={16} fontWeight={400} color='#fff'
                                            sx={{borderBottom: '1px solid #ffffff20', pb: .3}}>6000 Kecskemét, Kisfaludy utca 8.</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction='row' sx={{pt: 10, pb: 4}} spacing={4}>
                    <Typography fontSize={14} fontWeight={400} color='#8B949D'>© 2025 Tartozáskezelés.hu</Typography>
                    <Stack direction='row' spacing={1}>
                        <Typography fontSize={14} fontWeight={400} color='#8B949D'>Adatvédelmi tájékoztató</Typography>
                        <Divider orientation="vertical" flexItem variant='middle' sx={{background: '#8B949D'}} />
                        <Typography fontSize={14} fontWeight={400} color='#8B949D'>Cégadatok</Typography>
                        <Divider orientation="vertical" flexItem variant='middle' sx={{background: '#8B949D'}} />
                        <Typography fontSize={14} fontWeight={400} color='#8B949D'>Visszaélések bejelentése</Typography>
                    </Stack>
                </Stack>
            </Stack>*/}
        </footer>
    )
}

Footer.propTypes = {
    handleLink: PropTypes.func,
    screenType: PropTypes.string
}