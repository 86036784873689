import {
    Box,
    Checkbox,
    FormControl,
    Link,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from "../contexts/use-recaptcha";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";


const useStyles = makeStyles(() => ({

    button: {
        borderRadius: '16px',
        background: '#084AD8',
        color: '#fff',
        width: '100%',
        height: 56,
        cursor: 'pointer',
        transition: 'background ease-in-out .3s',
        marginTop: 80,
        '&:hover': {
            background: '#215CDC',
            transition: 'background ease-in-out .3s'
        }
    },

    disabledButton: {
        background: '#084AD820',
        color: '#E6EDFB',
        pointerEvents: 'none',
        '&:hover': {
            background: '#084AD820'
        }
    }
}))

const phonePreTags = [
    {
        id: 1,
        flag: '/images/flag-hu.png',
        text: '+36'
    }, {
        id: 2,
        flag: '/images/flag-de.png',
        text: '+49'
    }, {
        id: 3,
        flag: '/images/flag-at.png',
        text: '+46'
    }, {
        id: 4,
        flag: '/images/flag-ro.png',
        text: '+40'
    }, {
        id: 5,
        flag: '/images/flag-sk.png',
        text: '+421'
    }
]

const reasonTypes = [
    {id: 1, text: 'Általános érdeklődés'},
    {id: 2, text: 'Szolgáltatáscsomag'},
    {id: 3, text: 'Pénzügyi követelés kezelése'},
    {id: 4, text: 'Jogi tanácsadás'},
    {id: 5, text: 'Prevenciós megoldások'},
]

export default function Contact({setOpenAlert, screenType}) {
    const classes = useStyles()

    const {captchaToken, recaptchaRef, handleRecaptcha} = useRecaptcha()

    const [inputs, setInputs] = useState({
        'firstname': '',
        'lastname': '',
        'company': '',
        'phone_pre': 1,
        'phone': '',
        'email': '',
        'reason': null,
        'terms': false
    })

    const handleChange = (event) => {
        const { name, value } = event.target
        setInputs(values => ({...values, [name]: value}))
    }

    const handleNumberChange = (event) => {
        const { name, value } = event.target
        const nums = value.replace(/[^0-9]/g, '');
        setInputs(values => ({...values, [name]: nums}))
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target
        setInputs(values => ({...values, [name]: checked}))
    }


    const handleSubmit = async () => {

        /*const data = {}
        Object.keys(inputs).map((item) => {
            return data[item] = inputs[item]
        })

        if (data['terms'] === true) {

            console.log(data)
        }*/

        setOpenAlert()
    }

    const calcSize = (s) => {

        switch (screenType) {
            case 'tablet':
                if (s === 't1') return 40
                if (s === 't2') return 3

                break
            case 'phone':
                if (s === 't1') return 40
                if (s === 't2') return 3

                break
            default:
                if (s === 't1') return 48
                if (s === 't2') return 5
                break
        }
    }

    return (
        <section id='contact' style={{background: '#1949B0'}}>
            <Box sx={{flexGrow: 1, maxWidth: 1200, m: '0 auto', pt: (screenType === 'desktop') ? '80px' : '40px', pb: (screenType === 'desktop') ? 0 : '40px'}}>

                <Grid container spacing={2} sx={{p: 4, pb: 0}}>

                    <Grid size={{lg: 6, md: 12}}>
                        <Stack spacing={calcSize('t2')}>
                            <Typography fontSize={calcSize('t1')} fontWeight={500} color='#fff'>Vegye fel velünk a kapcsolatot</Typography>
                            <Typography fontSize={18} fontWeight={400} color='#fff'>Kérdése van, vagy segítségre van szüksége követelése rendezésében? Töltse ki az űrlapot, vagy hívjon minket.</Typography>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <img src='/images/contact-phone-icon.png' alt='contact-phone-icon' width={48} height={48} />
                                <Link href='tel:+36706461848' style={{ textDecoration: 'none' }}>
                                    <Typography fontSize={18} fontWeight={400} color='#fff'>+36 70 646 1848</Typography>
                                </Link>
                            </Stack>
                        </Stack>


                        {(screenType === 'desktop') ?
                            <img src='/images/contact-picture.webp' alt='contact-picture' style={{padding: '40px 0 0 0', margin: 0, width: '100%', height: 'auto'}} />
                            : <></>
                        }
                    </Grid>
                    <Grid size={{lg: 6, md: 12}}>
                        <Box sx={{background: '#fff', borderRadius: '16px', p: 5}}>

                            <form onSubmit={handleSubmit}>
                                <Stack spacing={4}>
                                    <Typography fontSize={16} fontWeight={400} color='#10283F'>Töltse ki az űrlapot, és szakértő kollégánk hamarosan felveszi Önnel a kapcsolatot.</Typography>

                                    <FormControl fullWidth>
                                        <Typography sx={{pb: 1, color: '#10283F'}}>Vezetéknév</Typography>
                                        <TextField id="firstname" variant="standard"
                                                   name='firstname'
                                                   sx={{borderRadius: '12px', background: '#F0F4F5', outline: 'none'}}
                                                   value={inputs?.firstname || ''}
                                                   onChange={handleChange}
                                                   slotProps={{
                                                       input: {
                                                           disableUnderline: true,
                                                           sx: {height: 48,px: 2}}
                                                   }} />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <Typography sx={{pb: 1, color: '#10283F'}}>Keresztnév</Typography>
                                        <TextField id="lastname" variant="standard"
                                                   name='lastname'
                                                   sx={{borderRadius: '12px', background: '#F0F4F5', outline: 'none'}}
                                                   value={inputs?.lastname || ''}
                                                   onChange={handleChange}
                                                   slotProps={{
                                                       input: {
                                                           disableUnderline: true,
                                                           sx: {height: 48,px: 2}}
                                                   }} />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <Stack direction='row' spacing={1}>
                                            <Typography sx={{pb: 1, color: '#10283F'}}>Cég</Typography>
                                            <Typography sx={{pb: 1, color: '#566370'}}>(opcionális)</Typography>
                                        </Stack>
                                        <TextField id="company" variant="standard"
                                                   name='company'
                                                   sx={{borderRadius: '12px', background: '#F0F4F5', outline: 'none'}}
                                                   value={inputs?.company || ''}
                                                   onChange={handleChange}
                                                   slotProps={{
                                                       input: {
                                                           disableUnderline: true,
                                                           sx: {height: 48,px: 2}}
                                                   }} />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <Typography sx={{pb: 1, color: '#10283F'}}>Telefonszám</Typography>
                                        <Stack direction='row' spacing={2}>
                                            <Select variant='standard'
                                                    id='phone_pre'
                                                    name='phone_pre'
                                                    disableUnderline
                                                    displayEmpty
                                                    value={inputs?.phone_pre}
                                                    onChange={handleChange}
                                                    sx={{borderRadius: '12px', background: '#F0F4F5', outline: 'none', width: '25%', px: 2}}>
                                                {phonePreTags.map(item => {
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            <Stack direction='row' spacing={1}>
                                                                <img src={item.flag} alt='-' width={24} height={24} />
                                                                <Typography sx={{color: '#10283F', fontSize: 16, fontWeight: 400}}>{item.text}</Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            <TextField id="phone" variant="standard"
                                                       name='phone'
                                                       sx={{borderRadius: '12px', background: '#F0F4F5', outline: 'none', flexGrow: 1}}
                                                       value={inputs?.phone || ''}
                                                       onChange={handleNumberChange}
                                                       slotProps={{
                                                           input: {
                                                               disableUnderline: true,
                                                               sx: {height: 48,px: 2}}
                                                       }} />
                                        </Stack>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <Typography sx={{pb: 1, color: '#10283F'}}>E-mail cím</Typography>
                                        <TextField id="email" variant="standard"
                                                   name='email'
                                                   sx={{borderRadius: '12px', background: '#F0F4F5', outline: 'none'}}
                                                   value={inputs?.email || ''}
                                                   onChange={handleChange}
                                                   slotProps={{
                                                       input: {
                                                           disableUnderline: true,
                                                           sx: {height: 48,px: 2}}
                                                   }} />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <Stack direction='row' spacing={1}>
                                            <Typography sx={{pb: 1, color: '#10283F'}}>Miben segíthetünk?</Typography>
                                            <Typography sx={{pb: 1, color: '#566370'}}>(opcionális)</Typography>
                                        </Stack>
                                        <Select variant='standard'
                                                id='reason'
                                                name='reason'
                                                disableUnderline
                                                displayEmpty
                                                value={inputs?.reason || ''}
                                                onChange={handleChange}
                                                sx={{borderRadius: '12px', background: '#F0F4F5', outline: 'none', px: 2, height: 48}}>
                                            {reasonTypes.map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Typography sx={{color: '#10283F', fontSize: 16, fontWeight: 400}}>{item.text}</Typography>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <Stack direction='row' spacing={1} alignItems='flex-start'>
                                            <Checkbox id='terms' value={inputs?.terms || false}
                                                      name='terms'
                                                      onChange={handleCheckboxChange} />
                                            <Typography sx={{pb: 1, color: '#10283F', fontSize: '14px'}}>Hozzájárulok, hogy a Tartozáskezelés.hu az <Link>adatvédelmi tájékoztatóban</Link> foglaltak szerint kezelje az adataimat.</Typography>
                                        </Stack>
                                    </FormControl>

                                    <Box className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                        <ReCAPTCHA ref={recaptchaRef}
                                                   sitekey='6Lde7-8qAAAAAPaVCS6m1flD6zQJBkUJGHxxZsMW'
                                                   onChange={handleRecaptcha} size='normal' />
                                    </Box>

                                    <Box className={[classes.button, (!captchaToken) ? classes.disabledButton :'']} alignItems='center' display='flex' justifyContent='center'
                                         onClick={async() => {
                                             await handleSubmit()
                                         }}>
                                        <Typography>Küldés</Typography>
                                    </Box>
                                    {/*<Button disabled={!captchaToken} type='submit'
                                        sx={{textTransform:'none', letterSpacing: 1, borderRadius: '16px',
                                            background: '#084AD8', color: '#fff', width: '100%', height: 56, mt: '80px'}}>Küldés</Button>*/}
                                </Stack>
                            </form>

                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </section>
    )
}

Contact.propTypes = {
    setOpenAlert: PropTypes.func,
    screenType: PropTypes.string
}